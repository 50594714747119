import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import peace from "../../assets/images/icons/peacevillage.png";
import addpic from "../../assets/images/icons/addpic.png";
import '../BDO/Dialysis/DialysisForm.css'
import { useAlert } from "react-alert";
import PhoneInput from "react-phone-input-2";
import axios from "axios";
import { APIURL } from "../../Global";
import SubmitConfirmationPopup from "../common/SubmitConfirm";
import { ConfirmationContext } from "../contexts/SubmitConfirmationContext";
import { IoIosCloseCircleOutline } from "react-icons/io";
import DatePicker from 'react-date-picker';
import Select from 'react-select';
import { MdOutlineFileUpload } from "react-icons/md";
import SuccessModal from "./SuccessModal";
function PhysioForm() {
    const [showConfirmation, setShowConfirmation, closeConfirmationPopup] = useContext(ConfirmationContext);
    const [resubmission, setResubmission] = useState(false)
    const [error, setError] = useState({})
    const [uploadShow, setUploadShow] = useState(false)
    const [validShow, setValidShow] = useState(false)
    const [show, setShow] = useState(false)
    const [modalDetails, setModalDetails] = useState('')
    const [dob, setDob] = useState();
    const [img, setImg] = useState("")
    const [previewOn, setPreviewOn] = useState(false)
    const alert = useAlert();
    const inputRefs = useRef([])
    const submitButtonRef = useRef(null)
    const navigate = useNavigate();
    const reader = new FileReader();
    const [relativesList, setRelativesList] = useState([]);
    const [files, setFiles] = useState([]);
    const [isValidIdentity, setIsValidIdentity] = useState(true);
    const [fileNames, setFileNames] = useState({
        referral_document: "Upload File",
        medical_prescription: "Upload File",
    });
    const [relative, setRelative] = useState({
        name: '',
        relation: '',
        contact: '91',
        occupation: '',
        income: ''
    });
    const [recommend1, setRecommend1] = useState({
        name: '',
        relation: '',
        contact: '91',
    });
    const [recommend2, setRecommend2] = useState({
        name: '',
        relation: '',
        contact: '91',
    });
    const [emergency, setEmergency] = useState({
        name: '',
        relation: '',
        contact: '91',
        secondary_contact: '91'
    });
    const [userDatas, setUserDatas] = useState({
        firstname: "",
        lastname: '',
        phone: {
            dialcode: "",
            rawphone: "",
            mobile_number: "",
        },
        dob: "",
        age: "",
        gender: "",
        occupation: "",
        province: "",
        city: "",
        location: "",
        pincode: "",
        country: "India",
        identitycard: { value: '', label: '' },
        identitynumber: "",
        insuranceprovider: "",
        insurancenumber: "",
        distance: "",
        img_url: "",
        referral_name: "",
        referral_document: "",
        medical_prescription: "",
        med_information: '',
        income: ''

    });

    const identityoptions = [
        { value: '', label: 'Select type' },
        { value: 'pan', label: 'PAN' },
        { value: 'passport', label: 'PASSPORT' },
        { value: 'aadhar', label: 'AADHAR' },
        { value: 'driving_license', label: 'DRIVING LICENCE' },
        { value: 'ration_card', label: 'RATION CARD' },
        { value: 'voter_id', label: 'VOTER ID' },]

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const handleKeyDown = (e, index) => {
        if (e.key === "Enter") {
            e.preventDefault()
            if (index === 20) {
                inputRefs.current[21].click();
            } else if (index === 33) {
                submitButtonRef.current.click();
            } else {
                e.preventDefault();
                const nextIndex = index + 1;
                if (nextIndex < inputRefs.current.length && inputRefs.current[nextIndex]) {
                    inputRefs.current[nextIndex].focus();
                }
            }
        }
    };

    const handleKey = (e) => {
        if (e.altKey && e.key === 's') {
            submitButtonRef.current.click();
        } else if (e.altKey && e.key === 'c') {
            navigate("../");
        }
    };

    useEffect(() => {
        document.addEventListener("keydown", handleKey);

        return () => {
            document.removeEventListener("keydown", handleKey);
        };
    }, []);

    const trimspace = (val) => {

        return val.startsWith(' ') ? val.trimLeft() : val;
    }

    const handleChange = (e) => {
        e.preventDefault();
        const val = trimspace(e.target.value);
        const key = e.target.name;

        setError((currentstate) => ({
            ...currentstate,
            [key]: "",
        }));

        setUserDatas(currentstate => ({
            ...currentstate,
            [key]: val
        }));
        if (key === 'income') {
            const sanitizedValue = val.replace(/[^0-9]/g, '');
            setUserDatas(currentstate => ({
                ...currentstate,
                [key]: sanitizedValue
            }));
        }
        if (key === 'referral_name' && val === '') {
            setError(currentstate => ({
                ...currentstate,
                referral_document: ''
            }));
            setUserDatas(currentstate => ({
                ...currentstate,
                referral_document: ''
            }));
            setFileNames(currentstate => ({
                ...currentstate,
                referral_document: ''
            }));

        }

        if (key === "insuranceprovider" && val === '') {
            setUserDatas(currentstate => ({
                ...currentstate,
                insurancenumber: ''
            }));
            setError((currentstate) => ({
                ...currentstate,
                insurancenumber: '',
            }));
        } else if (key === "insuranceprovider" && val !== '') {
            setError((currentstate) => ({
                ...currentstate,
                insurancenumber: 'Insurance number required',
            }));
        }

    }

    const IdentityChange = (data) => {

        setUserDatas(current => ({
            ...current, identitycard: data,
            identitynumber: ''
        }))

        setError((currentstate) => ({
            ...currentstate,
            identitynumber: '',
        }))

        if (data.value !== '') {
            if (!userDatas.identitynumber || userDatas.identitynumber === '' || userDatas.identitynumber === null) {
                setError((currentstate) => ({
                    ...currentstate,
                    identitynumber: 'Enter Identity Number',
                }))
            }
        } else if (data.value === '') {
            setUserDatas(current => ({
                ...current, identitynumber: ''
            }))
            setError((currentstate) => ({
                ...currentstate,
                identitynumber: '',
            }))
        }
    }

    const handleChangeidnum = (e) => {
        setIsValidIdentity(true)
        setError((currentstate) => ({
            ...currentstate,
            identitynumber: "",
        }));

        const x = e.target.value
        const val = x.trim()
        const key = e.target.name;

        setUserDatas((currentstate) => ({
            ...currentstate,
            [key]: val,
        }));

        if (val === '') {
            setError((currentstate) => ({
                ...currentstate,
                identitynumber: "Enter Identity number",
            }));
        }

        if (userDatas.identitycard !== null && userDatas.identitynumber !== "" && userDatas.identitycard === userDatas.identitycard.value && userDatas.identitynumber === val) {
            setIsValidIdentity(true)
            setError((currentstate) => ({
                ...currentstate,
                identitynumber: "",
            }));

        } else {
            const data = {
                idcard_no: val,
                idcard_type: userDatas.identitycard.value,
            };
            axios
                .post(`${APIURL}/api/v1/account/check-id/ `, data, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                .then((res) => {
                    if (res.status === 200) {
                        if (res.data.status === "error") {
                            setError((currentstate) => ({
                                ...currentstate,
                                identitynumber: `This is already registered!`,
                            }));
                            setIsValidIdentity(false);
                        } else {
                            setIsValidIdentity(true);
                        }
                    } else {
                        setIsValidIdentity(true);
                    }
                })
                .catch((err) => { });
        }
    }

    const uploadImage = async (e) => {
        setError((currentstate) => ({
            ...currentstate,
            img: '',
        }))
        const file = e.target.files[0];

        if (!file) {
            setUploadShow(!uploadShow);
            return false;
        }
        if (!file.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG)$/)) {
            setValidShow(!validShow);
            return false;
        }
        if (file.size > 10e6) {

            return false;
        }

        reader.onload = (e) => {
            const img = new Image();
            img.onload = () => {
                setImg(file);
            };

            img.onerror = () => {
                setPreviewOn(false);
                return false;
            };
            img.src = e.target.result;
        };

        reader.readAsDataURL(file);

        const objectURL = URL.createObjectURL(file);
        setUserDatas((currentstate) => ({
            ...currentstate,
            img_url: objectURL,
        }));
        setPreviewOn(true);
    };

    const uploadImage1 = async (e) => {

        const key = e.target.name;
        const file = e.target.files[0];
        setError((currentstate) => ({
            ...currentstate,
            [key]: "",
        }));

        if (!file) {
            alert.error("Please Select a File");
            setFileNames((currentFileNames) => ({
                ...currentFileNames,
                [key]: "Upload File",
            }));
            return;
        }

        if (!file.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG|pdf|PDF)$/)) {
            alert.error("Select a valid image or PDF file");
            setFileNames((currentFileNames) => ({
                ...currentFileNames,
                [key]: "Upload File",
            }));
            return;
        }

        if (file.size > 10e6) {
            alert.error("Select a file smaller than 10MB");
            setFileNames((currentFileNames) => ({
                ...currentFileNames,
                [key]: "Upload File",
            }));
            return;
        }

        const shortFileName = file.name.length > 10 ? file.name.slice(0, 10) + '...' : file.name;

        if (file.type.startsWith('image/')) {
            setFileNames((currentFileNames) => ({
                ...currentFileNames,
                [key]: shortFileName,
            }));
            setUserDatas((currentData) => ({
                ...currentData,
                [key]: file,
            }));
        } if (file.type === 'application/pdf') {
            setFileNames((currentFileNames) => ({
                ...currentFileNames,
                [key]: file.name,
            }));
            setUserDatas((currentData) => ({
                ...currentData,
                [key]: file,
            }));

        }
    };

    const handleGenderRadioButton = (e) => {
        let genderOpted = e.target.value;
        setUserDatas(currentstate => ({
            ...currentstate,
            gender: genderOpted

        }))
        setError(currentstate => ({
            ...currentstate,
            gender: ""
        }))
    }

    const handleDOB = (date) => {
        setError((currentstate) => ({
            ...currentstate,
            dob: '',
        }))
        setDob(date);
        let dateUpdated = date;
        if (date !== null) {
            const offset = date.getTimezoneOffset();
            dateUpdated = new Date(date.getTime() - (offset * 60 * 1000))
            let DateExtracted = dateUpdated.toISOString().split('T')[0];
            const age = new Date().getFullYear() - new Date(DateExtracted).getFullYear();
            setUserDatas(currentstate => ({
                ...currentstate,
                dob: DateExtracted,
                age: age
            }));
        }
        else if (date === null || date === "") {
            setUserDatas(currentstate => ({
                ...currentstate,
                dob: "",
                age: ""
            }));
        }
    }

    const handleChangeAge = (event) => {
        setError((currentstate) => ({
            ...currentstate,
            dob: '',
        }))
        const inputAge = parseInt(event.target.value, 10);
        if (!isNaN(inputAge) && inputAge >= 1 && inputAge <= 150) {
            const currentDate = new Date();
            const birthYear = currentDate.getFullYear() - inputAge;
            const birthDate = new Date(currentDate);
            birthDate.setFullYear(birthYear);

            setDob(birthDate);
            setUserDatas((currentState) => ({
                ...currentState,
                dob: birthDate.toISOString().split('T')[0],
                age: event.target.value
            }));

        } else if (event.target.value === "") {
            setDob(null);
            setUserDatas((currentState) => ({
                ...currentState,
                dob: null,
                age: null
            }));
        }
    };

    const handlePhoneInput = (value, data, event, formattedValue) => {
        const rawPhone = value.slice(data.dialCode.length);
        const dialcode = value.slice(0, data.dialCode.length);
        const numericValue = rawPhone.replace(/\D/g, ''); // Remove non-numeric characters
        setError({ ...error, phone: null });
        setUserDatas(currentstate => ({
            ...currentstate,
            phone: {
                dialcode: dialcode,
                rawphone: rawPhone,
                mobile_number: value
            }
        }));

        if (numericValue.length === 0 && dialcode.length === 2) {
            setError({ ...error, phone: "" });
            setUserDatas(currentstate => ({
                ...currentstate,
                phone: {
                    dialcode: dialcode,
                    rawphone: "",
                    mobile_number: "91"
                }
            }));
        }
    }
    const handleRelativeChange = (e) => {
        e.preventDefault();
        const value = trimspace(e.target.value);
        const key = e.target.name
        setRelative((currentstate) => ({
            ...currentstate,
            [key]: value,

        }))

        if (key === "name") {
            if (value === "") {
                setRelative((currentstate) => ({
                    ...currentstate,
                    name: '',
                    relation: '',
                    contact: '91',
                    occupation: '',
                    income: ''
                }))
            }
            else {
                setError((currentstate) => ({
                    ...currentstate,
                    relativename: '',

                }))
            }
        } else if (key === "relation") {
            setError((currentstate) => ({
                ...currentstate,
                relativerelation: '',
            }))
        } else if (key === "occupation") {
            setError((currentstate) => ({
                ...currentstate,
                relativeoccupation: '',
            }))
        } else if (key === "income") {
            setError((currentstate) => ({
                ...currentstate,
                relativeincome: '',
            }))
        }
        if (key === 'income') {
            const sanitizedValue = value.replace(/[^0-9]/g, '');
            setRelative(currentstate => ({
                ...currentstate,
                [key]: sanitizedValue
            }));
        }
    };

    const addRelative = () => {
        setError((currentstate) => ({
            ...currentstate,
            relatimename: '',
            relativecontact: '',
            relativerelation: '',
            relativeoccupation: '',
            relativeincome: ''
        }))
        if (relative.name && relative.contact && relative.contact !== "91" && relative.contact.length === 12 && relative.name !== "" && relative.name !== null && relative.relation !== "" && relative.occupation !== "" && relative.income !== "") {
            setRelativesList([...relativesList, relative]);
            setRelative({
                name: '',
                relation: '',
                contact: '91',
                occupation: '',
                income: ''
            });
        } else {
            let errors = {};
            if (!relative.name || relative.name === "" || relative.name === null) {
                errors.relativename = 'Name is required';
            }
            if (!relative.relation || relative.relation === "" || relative.relation === null) {
                errors.relativerelation = 'Relation is required';
            }
            if (!relative.contact || relative.contact === "91" || relative.contact === "") {
                errors.relativecontact = 'Contact no is required';
            }
            if (relative.contact !== "91" && relative.contact.length < 12) {
                errors.relativecontact = 'check number of digits';
            }
            if (!relative.occupation || relative.occupation === "" || relative.occupation === null) {
                errors.relativeoccupation = 'Occupation is required';
            }
            if (!relative.income || relative.income === "" || relative.income === null) {
                errors.relativeincome = 'Income is required';
            }
            setError(errors)
        }
    };

    const handleDeleteRelative = (indexToDelete) => {
        const updatedRelatives = relativesList.filter(
            (_, index) => index !== indexToDelete
        );
        setRelativesList(updatedRelatives);
    };

    const handleRecommend1Change = (e) => {
        e.preventDefault();
        const value = trimspace(e.target.value);
        const key = e.target.name
        if (key === "name" && value === "") {
            setRecommend1((currentstate) => ({
                ...currentstate,
                name: '',
                relation: '',
                contact: '91',
            }))
            setRecommend2((currentstate) => ({
                ...currentstate,
                name: '',
                relation: '',
                contact: '91',
            }))
            setError((currentstate) => ({
                ...currentstate,
                recommend1contact: '',
                recommend2contact: '',
            }))
            setFiles([])
        }
        if (key === "name") {
            setError((currentstate) => ({
                ...currentstate,
                recommend1name: '',
            }))
        }
        if (key === "relation") {
            setError((currentstate) => ({
                ...currentstate,
                recommend1relation: '',
            }))
        }
        setRecommend1((currentstate) => ({
            ...currentstate,
            [key]: value,

        }))
    };

    const handleRecommend2Change = (e) => {
        e.preventDefault();
        const value = trimspace(e.target.value);
        const key = e.target.name
        if (key === "name" && value === "") {
            setRecommend2((currentstate) => ({
                ...currentstate,
                name: "",
                relation: "",
                contact: "91",
            }))
            setError((currentstate) => ({
                ...currentstate,
                recommend2contact: '',
            }))
        }
        if (key === "relation") {
            setError((currentstate) => ({
                ...currentstate,
                recommend2relation: '',
            }))
        }
        setRecommend2((currentstate) => ({
            ...currentstate,
            [key]: value,

        }))
    };

    const handleEmergencyChange = (e) => {
        const value = trimspace(e.target.value);
        const key = e.target.name
        if (key === "name" && value === "") {
            setEmergency((currentstate) => ({
                ...currentstate,
                name: "",
                relation: "",
                contact: "91",
                secondary_contact: "91",
            }))
            setError((currentstate) => ({
                ...currentstate,
                emergencyname: '',
                emergencyrelation: '',
                emergencycontact: '',
                secondary_contact: '',
            }))
        }
        if (key === "name") {
            setError((currentstate) => ({
                ...currentstate,
                emergencyname: '',
            }))
        }
        if (key === "relstion") {
            setError((currentstate) => ({
                ...currentstate,
                emergencyrelation: '',
            }))
        }
        setEmergency((currentstate) => ({
            ...currentstate,
            [key]: value,

        }))
    };

    const validate = () => {
        let input = userDatas;
        let errors = {};
        let isValid = true;

        setError({});

        if (!input["firstname"] || input["firstname"] === "") {
            isValid = false;
            errors.firstname = 'Name is required';
        }

        if (!input["phone"] || typeof input["phone"] !== 'object') {
            isValid = false;
            errors.phone = 'Phone details are required';
        } else {
            if (!input.phone || input.phone.mobile_number === "") {
                isValid = false;
                if (!errors.phone) errors.phone = {};
                errors.phone = 'Mobile number is required';
            }
        }
        if (!input["occupation"] || input["occupation"] === "") {
            isValid = false;
            errors.occupation = 'Occupation is required';
        }
        if (!input["income"] || input["income"] === "") {
            isValid = false;
            errors.income = 'Income is required';
        }
        if (!input["city"] || input["city"] === "") {
            isValid = false;
            errors.city = 'City is required';
        }
        if (!input["province"] || input["province"] === "") {
            isValid = false;
            errors.province = 'District is required';
        }
        if (!input["panchayath"] || input["panchayath"] === "") {
            isValid = false;
            errors.panchayath = 'Panchayath is required';
        }
        if (!input["pincode"] || input["pincode"] === "") {
            isValid = false;
            errors.pincode = 'Pincode is required';
        }
        if (!input["dob"] || input["dob"] === "") {
            isValid = false;
            errors.dob = 'Age is required';
        }

        if (!input["gender"] || input["gender"] === "") {
            isValid = false;
            errors.gender = 'Gender is required';
        }

        if (!input["location"] || input["location"] === "") {
            isValid = false;
            errors.location = 'Location is required';
        }
        if (userDatas.identitycard.value !== "" && userDatas.identitycard.value !== null && userDatas.identitycard.value !== undefined) {
            if (!input["identitynumber"] || input["identitynumber"] === "") {
                isValid = false;
                errors.identitynumber = 'Identity number is required';
            }
        }
        if (userDatas.insuranceprovider !== "" && userDatas.insuranceprovider !== null && userDatas.insuranceprovider !== undefined) {
            if (!input["insurancenumber"] || input["insurancenumber"] === "") {
                isValid = false;
                errors.insurancenumber = 'Insurance number is required';
            }
        }
        if (!img || img === "") {
            isValid = false;
            errors.img = 'Photo is required';
        }
        if (!isValidIdentity) {
            isValid = false;
            errors.identitynumber = 'Enter a valid identity number';
        }
        if (!input["med_information"] || input["med_information"] === "") {
            isValid = false;
            errors.med_information = 'Medical information is required';
        }
        if (!input["medical_prescription"] || input["medical_prescription"] === "") {
            isValid = false;
            errors.medical_prescription = 'Medical prescription is required';
        }
        if (!input["referral_name"] || input["referral_name"] === "") {
            isValid = false;
            errors.referral_name = 'Referral information is required';
        }
        if (!input["distance"] || input["distance"] === "") {
            isValid = false;
            errors.distance = 'Distance is required';
        }
        if (!input["phone"].mobile_number || input["phone"].mobile_number === "91") {
            isValid = false;
            errors.phone = 'Mobile number is required';
        }
        if (input["phone"].mobile_number.length > 2 && input["phone"].mobile_number.length < 12) {
            isValid = false;
            errors.phone = 'Check number of digits';
        }
        if (!emergency.name || emergency.name === '' || emergency.name === null) {
            isValid = false;
            errors.emergencyname = 'Emergency contact is mandatory';
        }
        if (!emergency.relation || emergency.relation === '' || emergency.relation === null) {
            isValid = false;
            errors.emergencyrelation = 'Relation is required';
        }
        if (emergency.contact === "91") {
            isValid = false;
            errors.emergencycontact = 'Enter contact number';
        }
        if (emergency.contact !== "91" && emergency.contact.length < 12) {
            isValid = false;
            errors.emergencycontact = 'Check no of digits';
        }
        if (emergency.secondary_contact === "91") {
            isValid = false;
            errors.emergencysecondary = 'Enter contact number';
        }
        if (emergency.secondary_contact !== "91" && emergency.secondary_contact.length < 12) {
            isValid = false;
            errors.emergencysecondary = 'Check no of digits';
        }
        if (!recommend1.name || recommend1.name === '' || recommend1.name === null) {
            isValid = false;
            errors.recommend1name = 'A recommender is mandatory';
        }
        if (!recommend1.relation || recommend1.relation === '' || recommend1.relation === null) {
            isValid = false
            errors.recommend1relation = 'Enter designation'
        }
        if (recommend1.contact === "91") {
            isValid = false;
            errors.recommend1contact = 'Enter contact number';
        }
        if (recommend1.contact !== "91" && recommend1.contact.length < 12) {
            isValid = false;
            errors.recommend1contact = 'Check no of digits';
        }
        if (fileNames.referral_document === 'Upload File' || fileNames.referral_document === '' || fileNames.referral_document === undefined) {
            isValid = false;
            errors.referral_document = 'Referral consent is required';
        }
        if (recommend2.name !== '' && recommend2.name !== null) {
            if (!recommend2.relation || recommend2.relation === '' || recommend2.relation === null) {
                isValid = false
                errors.recommend2relation = 'Enter designation'
            }
            if (recommend2.contact === "91") {
                isValid = false;
                errors.recommend2contact = 'Enter contact number';
            }
            else if (recommend2.contact.length < 12) {
                isValid = false;
                errors.recommend2contact = 'Check no of digits';
            }
        }
        if (relativesList.length === 0) {
            isValid = false;
            errors.relativename = 'Name is required'
            errors.relativecontact = 'Contact is required'
            errors.relativeincome = 'Income is required'
            errors.relativerelation = 'Relation is required'
            errors.relativeoccupation = 'Occupation is required'
        }
        if (userDatas.phone.mobile_number !== '' && userDatas.phone.mobile_number !== '91' && userDatas.phone.mobile_number.length < 12) {
            isValid = false;
            errors.phone = 'check number of digits';
        }
        setError(errors);

        if (isValid) {
            setShowConfirmation(true);
        }
    };


    const handleConfirm = () => {
        closeConfirmationPopup()
        handleRegister("No");
    };

    const handleCancel = () => {
        setResubmission(false)
        closeConfirmationPopup()
    };

    const handleResubmission = () => {
        closeConfirmationPopup();
        setResubmission(false)
        handleRegister("Yes");
    };

    const handleRegister = async (shouldSaveAnyway) => {
        let govt_member = [];
        if (recommend1.name && recommend1.contact && recommend1.contact !== "91" && recommend1.name.trim() !== "") {
            govt_member.push(recommend1);
        }
        if (recommend2.name && recommend2.contact && recommend2.contact !== "91" && recommend2.name.trim() !== "") {
            govt_member.push(recommend2);
        }

        const formData = new FormData();
        formData.append('first_name', userDatas.firstname);
        formData.append('gender', userDatas.gender);
        formData.append('location', userDatas.location);
        formData.append('photo', img);

        if (userDatas.dob) formData.append('dob', userDatas.dob);
        if (userDatas.lastname) formData.append('last_name', userDatas.lastname);
        if (userDatas.phone.dialcode === "") formData.append('country_code', "91");
        if (userDatas.phone.rawphone) formData.append('mobile_number', userDatas.phone.dialcode + userDatas.phone.rawphone);
        if (userDatas.occupation) formData.append('occupation', userDatas.occupation);
        if (userDatas.province) formData.append('province', userDatas.province);
        if (userDatas.city) formData.append('city', userDatas.city);
        if (userDatas.pincode) formData.append('pincode', userDatas.pincode);
        if (userDatas.panchayath) formData.append('panchayat', userDatas.panchayath);
        if (userDatas.country) formData.append('country', userDatas.country);
        if (userDatas.identitycard.value) formData.append('idcard_type', userDatas.identitycard.value);
        if (userDatas.identitynumber) formData.append('idcard_no', userDatas.identitynumber);
        if (userDatas.insuranceprovider) formData.append('insurance_provider', userDatas.insuranceprovider);
        if (userDatas.insurancenumber) formData.append('policy_no', userDatas.insurancenumber);
        if (userDatas.distance) formData.append('route_map', userDatas.distance);
        if (userDatas.med_information) formData.append('med_information', userDatas.med_information);
        if (userDatas.medical_prescription) formData.append('med_document', userDatas.medical_prescription);
        if (userDatas.referral_name) formData.append('referral_name', userDatas.referral_name);
        if (userDatas.referral_document) formData.append('referral_document', userDatas.referral_document);
        if (emergency.name) formData.append('guardian_info', JSON.stringify(emergency));
        if (shouldSaveAnyway === "Yes") formData.append('save_anyway', shouldSaveAnyway);

        if (relativesList.length > 0) {
            formData.append('relatives', JSON.stringify(relativesList));
        }

        govt_member.forEach((item, i) => {
            formData.append(`govt_member[${i}]name`, item.name);
            formData.append(`govt_member[${i}]relation`, item.relation);
            formData.append(`govt_member[${i}]contact`, item.contact);
        });

        try {
            const res = await axios.post(`${APIURL}/api/v1/patient/physio-enquiry/`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            });

            if (res.data.status === "success") {
                alert.success(res.data.message);
                setModalDetails(`Successfully submitted the form with enquiry number ${res.data.phe_number}`)
                setShow(true)
            } else {
                if (res.data.message === "similar accounts found") {
                    setResubmission(true);
                } else {
                    alert.error(res.data.message);
                }
            }
        } catch (error) {
            alert.error('An error occurred while submitting the form.');
        }
    };

    const handleClose = () => {
        setShow(false)
        window.location.reload();
    }

    return (
        <div className='dialysis-container'>
            <div className='row title'>
                <img src={peace} className="peace-image col-2" alt="Peace Village" />
                <h4 className='dialysis-head col-9'>Application Form</h4>
            </div>
            <div className="row side-head">
                <div className="col-12">
                    <hr className="lines" />
                </div>
            </div>
            <div className='dialysis-formelements'>
                <div className='row'>
                    <div className='col-3'>
                        <div className='input-container'>
                            <label className={error.firstname ? 'error-label' : ''}>
                                {error.firstname || <>First Name<span> *</span></>}
                            </label>
                            <input
                                className={`input1 ${error.firstname ? 'error-border' : ''}`}
                                ref={el => inputRefs.current[0] = el}
                                onKeyDown={(e) => handleKeyDown(e, 0)}
                                type="text"
                                maxLength="50"
                                name="firstname"
                                value={userDatas.firstname}
                                onChange={handleChange}
                            />
                        </div>
                        <div className='input-container'>
                            <label className={error.phone ? 'error-label' : ''}>
                                {error.phone || <>Contact no<span> *</span></>}
                            </label>
                            <PhoneInput
                                style={{ border: error.phone ? '1px solid red' : 'none', borderRadius: '10px' }}
                                inputProps={{
                                    ref: el => inputRefs.current[2] = el,
                                    tabIndex: 0,
                                    onKeyDown: e => handleKeyDown(e, 2),
                                }}
                                value={userDatas.phone.mobile_number}
                                country={"in"}
                                onlyCountries={['in']}
                                countryCodeEditable={false}
                                onChange={handlePhoneInput}
                            />
                        </div>
                    </div>
                    <div className='col-3'>
                        <div className='input-container'>
                            <label>Last Name</label>
                            <input
                                className='input1'
                                ref={el => inputRefs.current[1] = el}
                                onKeyDown={(e) => handleKeyDown(e, 1)}
                                type="text"
                                maxLength="50"
                                value={userDatas.lastname}
                                name="lastname"
                                onChange={handleChange}
                            />
                        </div>
                        <div className='input-container'>
                            <label className={error.occupation ? 'error-label' : ''}>
                                {error.occupation || <>Occupation<span> *</span></>}
                            </label>
                            <input
                                className={`input1 ${error.occupation ? 'error-border' : ''}`}
                                ref={el => inputRefs.current[3] = el}
                                onKeyDown={(e) => handleKeyDown(e, 3)}
                                type="text"
                                maxLength="50"
                                value={userDatas.occupation}
                                name="occupation"
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className='col-4'>
                        <div className='input-container'>
                            <label className={error.gender ? 'error-label' : ''}>
                                {error.gender || <>Gender<span> *</span></>}
                            </label>
                            <div className={`input1 ${error.gender ? 'error-border' : ''}`}>
                                <div className="col-4">
                                    <input
                                        type="radio"
                                        id="male"
                                        name="gender"
                                        value="male"
                                        checked={userDatas.gender === "male"}
                                        onClick={handleGenderRadioButton}
                                    />
                                    <label htmlFor="male">Male</label>
                                </div>
                                <div className="col-4">
                                    <input
                                        type="radio"
                                        id="female"
                                        name="gender"
                                        value="female"
                                        checked={userDatas.gender === "female"}
                                        onClick={handleGenderRadioButton}
                                    />
                                    <label htmlFor="female">Female</label>
                                </div>
                                <div className="col-4">
                                    <input
                                        type="radio"
                                        id="others"
                                        name="gender"
                                        value="others"
                                        checked={userDatas.gender === "others"}
                                        onClick={handleGenderRadioButton}
                                    />
                                    <label htmlFor="others">Others</label>
                                </div>
                            </div>
                        </div>
                        <div className='input-container'>
                            <label className={error.dob ? 'error-label' : ''}>
                                {error.dob || <>Age or Date of Birth<span> *</span></>}
                            </label>
                            <div className={`input1 ${error.dob ? 'error-border' : ''}`}>
                                <DatePicker
                                    onChange={date => { handleDOB(date) }}
                                    maxDate={new Date()}
                                    value={dob}
                                />&nbsp;
                                <input
                                    ref={el => inputRefs.current[4] = el}
                                    onKeyDown={(e) => handleKeyDown(e, 4)}
                                    type="number"
                                    min="1"
                                    max="150"
                                    value={userDatas.age}
                                    placeholder="Age"
                                    name="age"
                                    onChange={handleChangeAge}>
                                </input>
                            </div>
                        </div>
                    </div>
                    <div className='col-2'>
                        <div className={`dialysis-profile ${error.img ? 'error-border' : ''}`}> {previewOn === false ? (
                            <div className="profile-container">
                                <input
                                    type="file"
                                    className="custom-file-input"
                                    id="validatedCustomFile"
                                    onChange={uploadImage}
                                />
                                <label style={{ cursor: "pointer" }} htmlFor="validatedCustomFile">
                                    <img
                                        src={addpic}
                                        alt="add profile"
                                    />
                                </label>
                                <label className={error.img ? 'error-label' : ''}>
                                    {error.img || <>Upload Photo<span> *</span></>}
                                </label>
                            </div>
                        ) : (
                            <div className="uploaded">
                                <IoIosCloseCircleOutline onClick={() => { setPreviewOn(false); setImg(''); setUserDatas(current => ({ ...current, img_url: '' })); }} className='closebutton-admission' />
                                <img
                                    src={userDatas.img_url}
                                    alt="Hospital Picture"
                                />
                            </div>
                        )}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-4">
                        <div className='input-container'>
                            <label className={error.income ? 'error-label' : ''}>
                                {error.income || <>Income<span> *</span></>}
                            </label>
                            <input
                                className={`input1 ${error.income ? 'error-border' : ''}`}
                                ref={el => inputRefs.current[5] = el}
                                onKeyDown={(e) => handleKeyDown(e, 5)}
                                type="text"
                                maxLength="50"
                                value={userDatas.income}
                                name="income"
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className="col-4">
                        <div className='input-container'>
                            <label className={error.city ? 'error-label' : ''}>
                                {error.city || <>City<span> *</span></>}
                            </label>
                            <input
                                className={`input1 ${error.city ? 'error-border' : ''}`}
                                ref={el => inputRefs.current[6] = el}
                                onKeyDown={(e) => handleKeyDown(e, 6)}
                                type="text"
                                maxLength="50"
                                value={userDatas.city}
                                name="city"
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className="col-4">
                        <div className='input-container'>
                            <label className={error.province ? 'error-label' : ''}>
                                {error.province || <>District<span> *</span></>}
                            </label>
                            <input
                                className={`input1 ${error.province ? 'error-border' : ''}`}
                                ref={el => inputRefs.current[7] = el}
                                onKeyDown={(e) => handleKeyDown(e, 7)}
                                type="text"
                                maxLength="50"
                                value={userDatas.province}
                                name="province"
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className="col-4">
                        <div className='input-container'>
                            <label className={error.location ? 'error-label' : ''}>
                                {error.location || <>Location<span> *</span></>}
                            </label>
                            <input
                                className={`input1 ${error.location ? 'error-border' : ''}`}
                                ref={el => inputRefs.current[8] = el}
                                onKeyDown={(e) => handleKeyDown(e, 8)}
                                type="text"
                                maxLength="50"
                                name="location"
                                value={userDatas.location}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className='col-4'>
                        <div className='input-container'>
                            <label className={error.panchayath ? 'error-label' : ''}>
                                {error.panchayath || <>Residing Panchayath<span> *</span></>}
                            </label>
                            <input
                                className={`input1 ${error.panchayath ? 'error-border' : ''}`}
                                ref={el => inputRefs.current[9] = el}
                                onKeyDown={(e) => handleKeyDown(e, 9)}
                                type="text"
                                maxLength="50"
                                value={userDatas.panchayath}
                                name="panchayath"
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className='col-4'>
                        <div className='input-container'>
                            <label className={error.pincode ? 'error-label' : ''}>
                                {error.pincode || <>Pincode<span> *</span></>}
                            </label>
                            <input
                                className={`input1 ${error.pincode ? 'error-border' : ''}`}
                                ref={el => inputRefs.current[10] = el}
                                onKeyDown={(e) => handleKeyDown(e, 10)}
                                type="text"
                                maxLength="50"
                                value={userDatas.pincode}
                                name="pincode"
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-4'>
                        <div className='input-container'>
                            <label>Country</label>
                            <input
                                className='input1'
                                value={"India"}
                                disabled type="text"
                            />
                        </div>
                    </div>
                    <div className='col-4'>
                        <div className='input-container'>
                            <label className={error.distance ? 'error-label' : ''}>
                                {error.distance || <>How long does it take to reach Peace Village?<span> *</span></>}
                            </label>
                            <input
                                className={`input1 ${error.distance ? 'error-border' : ''}`}
                                ref={el => inputRefs.current[11] = el}
                                onKeyDown={(e) => handleKeyDown(e, 11)}
                                type="text"
                                maxLength="50"
                                value={userDatas.distance}
                                name="distance"
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className='col-4'>
                        <div className='input-container'>
                            <label>ID Card Type</label>
                            <Select
                                ref={el => inputRefs.current[12] = el}
                                onKeyDown={(e) => handleKeyDown(e, 12)}
                                value={userDatas.identitycard.value !== "" ? userDatas.identitycard : "Select type"}
                                onChange={(data, e) => { IdentityChange(data, e) }}
                                options={identityoptions}
                                name='identitycard'
                                style={{ width: "100%", border: "none" }}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className='col-4'>
                        <div className='input-container'>
                            <label className={error.identitynumber ? 'error-label' : ''}>
                                {error.identitynumber || <>ID Card Number</>}
                            </label>
                            <input
                                className={`input1 ${error.identitynumber ? 'error-border' : ''}`}
                                ref={el => inputRefs.current[13] = el}
                                onKeyDown={(e) => handleKeyDown(e, 13)}
                                type="text"
                                maxLength="50"
                                value={userDatas.identitynumber}
                                name="identitynumber"
                                placeholder="Space not allowed"
                                disabled={userDatas.identitycard.value === ''}
                                onChange={handleChangeidnum}
                            />
                        </div>
                    </div>
                    <div className='col-4'>
                        <div className='input-container'>
                            <label>Insurance Provider</label>
                            <input
                                className='input1'
                                ref={el => inputRefs.current[14] = el}
                                onKeyDown={(e) => handleKeyDown(e, 14)}
                                type="text"
                                maxLength="50"
                                value={userDatas.insuranceprovider}
                                name="insuranceprovider"
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className='col-4'>
                        <div className='input-container'>
                            <label className={error.insurancenumber ? 'error-label' : ''}>
                                {error.insurancenumber || <>Insurance Number</>}
                            </label>
                            <input
                                className={`input1 ${error.insurancenumber ? 'error-border' : ''}`}
                                ref={el => inputRefs.current[15] = el}
                                onKeyDown={(e) => handleKeyDown(e, 15)}
                                type="text"
                                maxLength="50"
                                disabled={userDatas.insuranceprovider === ''}
                                value={userDatas.insurancenumber}
                                name="insurancenumber"
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                </div>
                <div className="row side-head">
                    <div className="col-2">
                        <label>Family Details</label><span style={{ color: "red" }}> *</span>
                    </div>
                    <div className="col-10">
                        <hr className="lines" />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-2'>
                        <div className='input-container'>
                            <label className={error.relativename ? 'error-label' : ''}>
                                {error.relativename || <>Name</>}
                            </label>
                            <input
                                className={`input1 ${error.relativename ? 'error-border' : ''}`}
                                ref={el => inputRefs.current[16] = el}
                                onKeyDown={(e) => handleKeyDown(e, 16)}
                                type="text"
                                maxLength="50"
                                name="name"
                                value={relative.name}
                                onChange={handleRelativeChange}
                            />
                        </div>
                    </div>
                    <div className='col-2'>
                        <div className='input-container'>
                            <label className={error.relativerelation ? 'error-label' : ''}>
                                {error.relativerelation || <>Relation</>}
                            </label>
                            <input
                                className={`input1 ${error.relativerelation ? 'error-border' : ''}`}
                                ref={el => inputRefs.current[17] = el}
                                onKeyDown={(e) => handleKeyDown(e, 17)}
                                type="text"
                                maxLength="50"
                                name="relation"
                                value={relative.relation}
                                onChange={handleRelativeChange}
                                disabled={!relative.name}
                            />
                        </div>
                    </div>
                    <div className='col-3'>
                        <div className='input-container'>
                            <label className={error.relativecontact ? 'error-label' : ''}>
                                {error.relativecontact || <>Contact no</>}
                            </label>
                            <PhoneInput
                                style={{ border: error.relativecontact ? '1px solid red' : 'none', borderRadius: '10px' }}
                                inputProps={{
                                    ref: el => inputRefs.current[18] = el,
                                    tabIndex: 0,
                                    onKeyDown: e => handleKeyDown(e, 18),
                                }}
                                onlyCountries={['in']}
                                countryCodeEditable={false}
                                value={relative.contact}
                                name="contact"
                                disabled={!relative.name}
                                onChange={(value) => {
                                    setError((currentstate) => ({
                                        ...currentstate,
                                        relativecontact: '',
                                    }))
                                    const numericValue = value.replace(/\D/g, "");
                                    if (numericValue.length < 12 && numericValue.length !== 2) {
                                        setRelative({
                                            ...relative,
                                            contact: "91",
                                        });
                                        setError((currentstate) => ({
                                            ...currentstate,
                                            relativecontact: 'Check number of digits',
                                        }))
                                    } else if (numericValue.length === 2) {
                                        setRelative({
                                            ...relative,
                                            contact: "91",
                                        });
                                        setError((currentstate) => ({
                                            ...currentstate,
                                            relativecontact: 'Enter contact number',
                                        }))
                                    }
                                    setRelative({
                                        ...relative,
                                        contact: value,
                                    });
                                }}
                            />
                        </div>
                    </div>
                    <div className='col-2'>
                        <div className='input-container'>
                            <label className={error.relativeoccupation ? 'error-label' : ''}>
                                {error.relativeoccupation || <>Occupation</>}
                            </label>
                            <input
                                className={`input1 ${error.relativeoccupation ? 'error-border' : ''}`}
                                ref={el => inputRefs.current[19] = el}
                                onKeyDown={(e) => handleKeyDown(e, 19)}
                                type="text"
                                maxLength="50"
                                name="occupation"
                                value={relative.occupation}
                                onChange={handleRelativeChange}
                                disabled={!relative.name}
                            />
                        </div>
                    </div>
                    <div className='col-2'>
                        <div className='input-container'>
                            <label className={error.relativeincome ? 'error-label' : ''}>
                                {error.relativeincome || <>Income</>}
                            </label>
                            <input
                                className={`input1 ${error.relativeincome ? 'error-border' : ''}`}
                                ref={el => inputRefs.current[20] = el}
                                onKeyDown={(e) => handleKeyDown(e, 20)}
                                type="text"
                                maxLength="40"
                                name="income"
                                value={relative.income}
                                onChange={handleRelativeChange}
                                disabled={!relative.name}
                            />
                        </div>
                    </div>
                    <div className='col-1'>
                        <div className="input-container"><button ref={el => inputRefs.current[21] = el} className="save-button" style={{ width: "50px" }} disabled={relativesList.length > 4} onClick={addRelative}>Add</button></div>
                    </div>
                </div>
                {relativesList.length > 0 && (
                    <div style={{ width: "90%", margin: "auto" }}>
                        <div className="row">
                            <div className="row">
                                <div className="col-1 relative-table t-color">
                                    SI.No
                                </div>
                                <div className="col-2 relative-table t-color">
                                    Name
                                </div>
                                <div className="col-2 relative-table t-color">
                                    Relation
                                </div>
                                <div className="col-2 relative-table t-color">
                                    Contact
                                </div>
                                <div className="col-2 relative-table t-color">
                                    Occupation
                                </div>
                                <div className="col-2 relative-table t-color">
                                    Income
                                </div>
                                <div className="col-1 relative-table t-color">
                                    Delete
                                </div>
                            </div>
                            {relativesList.map((item, index) => {
                                return (
                                    <>
                                        <div className="row">
                                            <div className="col-1 relative-table" >
                                                {index + 1}
                                            </div>
                                            <div className="col-2 relative-table" style={{ textTransform: 'capitalize' }}>
                                                {item.name}
                                            </div>
                                            <div className="col-2 relative-table" style={{ textTransform: 'capitalize' }}>
                                                {item.relation}{" "}
                                            </div>
                                            <div className="col-2 relative-table">
                                                {item.contact !== '91' ? item.contact : ""}
                                            </div>
                                            <div className="col-2 relative-table" style={{ textTransform: 'capitalize' }}>
                                                {item.occupation}
                                            </div>
                                            <div className="col-2 relative-table"   >
                                                {item.income}
                                            </div>
                                            <div className="col-1 relative-table" >
                                                <i
                                                    class="fa fa-trash"
                                                    style={{ color: "red", cursor: "pointer" }}
                                                    onClick={() => handleDeleteRelative(index)}
                                                    aria-hidden="true"
                                                ></i>
                                            </div>
                                        </div>
                                    </>
                                );
                            })}
                        </div>
                    </div>
                )}
                <div className="row side-head">
                    <div className="col-2">
                        <label>Medical Information</label>
                    </div>
                    <div className="col-10">
                        <hr className="lines" />
                    </div>
                </div>
                <div className="row">
                    <div className="col-8">
                        <div className='input-containersp'>
                            <label className={error.med_information ? 'error-label' : ''} >
                                {error.med_information || <>Do you have any current medical conditions? [Yes/No] If yes, please specify <span> *</span></>}
                            </label>
                            <input
                                className={`input1 ${error.med_information ? 'error-border' : ''}`}
                                ref={el => inputRefs.current[22] = el}
                                onKeyDown={(e) => handleKeyDown(e, 22)}
                                type="text"
                                maxLength="150"
                                value={userDatas.med_information}
                                name="med_information"
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className="col-4">
                        <div className='input-container'>
                            <label className={error.medical_prescription ? 'error-label' : ''}>
                                {error.medical_prescription || <>Upload Prescription<span> *</span></>}
                            </label>
                            <label className={`file-input ${error.medical_prescription ? 'error-border' : ''}`}>
                                <input
                                    type="file"
                                    style={{ display: "none" }}
                                    name="medical_prescription"
                                    onChange={uploadImage1} />
                                <label className="col-10">{fileNames["medical_prescription"]}</label>
                                <MdOutlineFileUpload className="upload-icon" />
                            </label>
                        </div>
                    </div>
                </div>
                <div className="row side-head">
                    <div className="col-2">
                        <label>Recommendation</label>
                    </div>
                    <div className="col-10">
                        <hr className="lines" />
                    </div>
                </div>
                <div className="row">
                    <div className="col-4">
                        <div className='input-container'>
                            <label className={error.recommend1name ? 'error-label' : ''}>
                                {error.recommend1name || <>Name<span> *</span></>}
                            </label>
                            <input
                                className={`input1 ${error.recommend1name ? 'error-border' : ''}`}
                                ref={el => inputRefs.current[23] = el}
                                onKeyDown={(e) => handleKeyDown(e, 23)}
                                type="text"
                                maxLength="50"
                                name="name"
                                value={recommend1.name}
                                onChange={handleRecommend1Change}
                            />
                        </div>
                    </div>
                    <div className="col-4">
                        <div className='input-container'>
                            <label className={error.recommend1relation ? 'error-label' : ''}>
                                {error.recommend1relation || <>Designation<span> *</span></>}
                            </label>
                            <input
                                className={`input1 ${error.recommend1relation ? 'error-border' : ''}`}
                                ref={el => inputRefs.current[24] = el}
                                onKeyDown={(e) => handleKeyDown(e, 24)}
                                type="text"
                                value={recommend1.relation}
                                maxLength="50"
                                name="relation"
                                onChange={handleRecommend1Change}
                                disabled={!recommend1.name}
                            />
                        </div>
                    </div>
                    <div className="col-4">
                        <div className='input-container'>
                            <label className={error.recommend1contact ? 'error-label' : ''}>
                                {error.recommend1contact || <>Contact no<span> *</span></>}
                            </label>
                            <PhoneInput
                                style={{ border: error.recommend1contact ? '1px solid red' : 'none', borderRadius: '10px' }}
                                inputProps={{
                                    ref: el => inputRefs.current[25] = el,
                                    tabIndex: 0,
                                    onKeyDown: e => handleKeyDown(e, 25),
                                }}
                                value={recommend1.contact}
                                country={"in"}
                                onlyCountries={['in']}
                                countryCodeEditable={false}
                                disabled={!recommend1.name}
                                onChange={(value) => {
                                    setError((currentstate) => ({
                                        ...currentstate,
                                        recommend1contact: '',
                                    }))
                                    const numericValue = value.replace(/\D/g, "");
                                    if (numericValue.length < 12 && numericValue.length !== 2) {
                                        setError((currentstate) => ({
                                            ...currentstate,
                                            recommend1contact: 'Check number of digits',
                                        }))
                                    } else if (numericValue.length === 2) {
                                        setRecommend1({
                                            ...recommend1,
                                            contact: "91",
                                        });
                                        if (recommend1.name !== '') {
                                            setError((currentstate) => ({
                                                ...currentstate,
                                                recommend1contact: 'Contact no is required',
                                            }))
                                        }
                                    }
                                    setRecommend1({
                                        ...recommend1,
                                        contact: value,
                                    });
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-4">
                        <div className='input-container'>
                            <label>Name</label>
                            <input
                                className='input1'
                                ref={el => inputRefs.current[26] = el}
                                onKeyDown={(e) => handleKeyDown(e, 26)}
                                type="text"
                                value={recommend2.name}
                                maxLength="50"
                                name="name"
                                onChange={handleRecommend2Change}
                            />
                        </div>
                    </div>
                    <div className="col-4">
                        <div className='input-container'>
                            <label className={error.recommend2relation ? 'error-label' : ''}>
                                {error.recommend2relation || <>Designation</>}
                            </label>
                            <input
                                className={`input1 ${error.recommend2relation ? 'error-border' : ''}`}
                                ref={el => inputRefs.current[27] = el}
                                onKeyDown={(e) => handleKeyDown(e, 27)}
                                type="text"
                                maxLength="50"
                                value={recommend2.relation}
                                name="relation"
                                onChange={handleRecommend2Change}
                                disabled={!recommend2.name}
                            />
                        </div>
                    </div>
                    <div className="col-4">
                        <div className='input-container'>
                            <label className={error.recommend2contact ? 'error-label' : ''}>
                                {error.recommend2contact || <>Contact no</>}
                            </label>
                            <PhoneInput
                                inputProps={{
                                    ref: el => inputRefs.current[28] = el,
                                    tabIndex: 0,
                                    onKeyDown: e => handleKeyDown(e, 28),
                                }}
                                value={recommend2.contact}
                                country={"in"}
                                onlyCountries={['in']}
                                countryCodeEditable={false}
                                disabled={!recommend2.name}
                                onChange={(value) => {
                                    setError((currentstate) => ({
                                        ...currentstate,
                                        recommend2contact: '',
                                    }))
                                    const numericValue = value.replace(/\D/g, "");
                                    if (numericValue.length < 12 && numericValue.length !== 2) {
                                        setError((currentstate) => ({
                                            ...currentstate,
                                            recommend2contact: 'Check number of digits',
                                        }))
                                    } else if (numericValue.length === 2) {
                                        setRecommend2({
                                            ...recommend2,
                                            contact: "91",
                                        });
                                        if (recommend2.name !== '') {
                                            setError((currentstate) => ({
                                                ...currentstate,
                                                recommend2contact: 'Contact no is required',
                                            }))
                                        }
                                    }
                                    setRecommend2({
                                        ...recommend2,
                                        contact: value,
                                    });
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="row side-head">
                    <div className="col-2">
                        <label>Referral Information</label>
                    </div>
                    <div className="col-10">
                        <hr className="lines" />
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <div className='input-container'>
                            <label className={error.referral_name ? 'error-label' : ''}>
                                {error.referral_name || <>Name of physician or health provider<span> *</span></>}
                            </label>
                            <input
                                className={`input1 ${error.referral_name ? 'error-border' : ''}`}
                                ref={el => inputRefs.current[29] = el}
                                onKeyDown={(e) => handleKeyDown(e, 29)}
                                type="text"
                                maxLength="50"
                                value={userDatas.referral_name}
                                name="referral_name"
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className="col-6">
                        <div className='input-container'>
                            <label className={error.referral_document ? 'error-label' : ''}>
                                {error.referral_document || <>Referral Consent<span>*</span></>}
                            </label>
                            <label className={`file-input ${error.referral_document ? 'error-border' : ''}`}>
                                <input
                                    type="file"
                                    style={{ display: "none" }}
                                    name="referral_document"
                                    onChange={uploadImage1} />
                                <label className="col-11">{fileNames["referral_document"]}</label>
                                <MdOutlineFileUpload className="upload-icon" />
                            </label>
                        </div>
                    </div>
                </div>
                <div className="row side-head">
                    <div className="col-2">
                        <label>Emergency Contact</label>
                    </div>
                    <div className="col-10">
                        <hr className="lines" />
                    </div>
                </div>
                <div className="row">
                    <div className="col-3">
                        <div className='input-container'>
                            <label className={error.emergencyname ? 'error-label' : ''}>
                                {error.emergencyname || <>Name<span> *</span></>}
                            </label>
                            <input
                                className={`input1 ${error.emergencyname ? 'error-border' : ''}`}
                                ref={el => inputRefs.current[30] = el}
                                onKeyDown={(e) => handleKeyDown(e, 30)}
                                type="text"
                                value={emergency.name}
                                maxLength="50"
                                name="name"
                                onChange={handleEmergencyChange}
                            />
                        </div>
                    </div>
                    <div className="col-3">
                        <div className='input-container' >
                            <label className={error.emergencyrelation ? 'error-label' : ''}>
                                {error.emergencyrelation || <>Relation<span> *</span></>}
                            </label>
                            <input
                                className={`input1 ${error.emergencyrelation ? 'error-border' : ''}`}
                                ref={el => inputRefs.current[31] = el}
                                onKeyDown={(e) => handleKeyDown(e, 31)}
                                type="text"
                                value={emergency.relation}
                                maxLength="50"
                                name="relation"
                                onChange={handleEmergencyChange}
                                disabled={!emergency.name}
                            />
                        </div>
                    </div>
                    <div className="col-3">
                        <div className='input-container'>
                            <label className={error.emergencycontact ? 'error-label' : ''}>
                                {error.emergencycontact || <>Contact no<span> *</span></>}
                            </label>
                            <PhoneInput
                                style={{ border: error.emergencycontact ? '1px solid red' : 'none', borderRadius: '10px' }}
                                inputProps={{
                                    ref: el => inputRefs.current[32] = el,
                                    tabIndex: 0,
                                    onKeyDown: e => handleKeyDown(e, 32),
                                }}
                                value={emergency.contact}
                                country={"in"}
                                onlyCountries={['in']}
                                countryCodeEditable={false}
                                disabled={!emergency.name}
                                onChange={(value) => {
                                    setError((currentstate) => ({
                                        ...currentstate,
                                        emergencycontact: '',
                                    }))
                                    const numericValue = value.replace(/\D/g, "");
                                    if (numericValue.length < 12 && numericValue.length !== 2) {
                                        setEmergency({
                                            ...emergency,
                                            contact: "91",
                                        });
                                        setError((currentstate) => ({
                                            ...currentstate,
                                            emergencycontact: 'Check number of digits'
                                        }))
                                    } else if (numericValue.length === 2) {
                                        setEmergency({
                                            ...emergency,
                                            contact: "91",
                                        });
                                        setError((currentstate) => ({
                                            ...currentstate,
                                            emergencycontact: 'Enter contact no',
                                        }))
                                    }
                                    setEmergency({
                                        ...emergency,
                                        contact: value,
                                    });
                                }}
                            />
                        </div>
                    </div>
                    <div className="col-3">
                        <div className='input-container'>
                            <label className={error.emergencysecondary ? 'error-label' : ''}>
                                {error.emergencysecondary || <>Secondary contact no <span>*</span></>}
                            </label>
                            <PhoneInput
                                style={{ border: error.emergencysecondary ? '1px solid red' : 'none', borderRadius: '10px' }}
                                inputProps={{
                                    ref: el => inputRefs.current[33] = el,
                                    tabIndex: 0,
                                    onKeyDown: e => handleKeyDown(e, 33),
                                }}
                                value={emergency.secondary_contact}
                                country={"in"}
                                onlyCountries={['in']}
                                countryCodeEditable={false}
                                disabled={!emergency.name || !emergency.secondary_contact}
                                onChange={(value) => {
                                    setError((currentstate) => ({
                                        ...currentstate,
                                        emergencysecondary: '',
                                    }))
                                    const numericValue = value.replace(/\D/g, "");
                                    if (numericValue.length < 12 && numericValue.length !== 2) {
                                        setEmergency({
                                            ...emergency,
                                            secondary_contact: "91",
                                        });
                                        setError((currentstate) => ({
                                            ...currentstate,
                                            emergencysecondary: 'Check number of digits'
                                        }))
                                    } else if (numericValue.length === 2) {
                                        setEmergency({
                                            ...emergency,
                                            secondary_contact: "91",
                                        });
                                    }
                                    setEmergency({
                                        ...emergency,
                                        secondary_contact: value,
                                    });
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <button className="save" ref={submitButtonRef} onClick={() => { validate() }}>Submit</button>
                </div>
            </div >
            {
                showConfirmation && (
                    <SubmitConfirmationPopup
                        message="Are you sure you want to submit the form?"
                        onConfirm={handleConfirm}
                        onCancel={handleCancel}
                    />
                )
            }
            {
                resubmission && (
                    <SubmitConfirmationPopup
                        message="Similar active accounts found. Are you sure you want to submit the form?"
                        onConfirm={handleResubmission}
                        onCancel={handleCancel}
                    />
                )
            }
            {
                show && <SuccessModal
                    handleClose={handleClose}
                    message={modalDetails}
                />
            }
        </div >
    );
}

export default PhysioForm;
