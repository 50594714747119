import React, { useState, useEffect, useRef } from 'react';
import { IoIosCloseCircleOutline } from "react-icons/io";
import { Switch } from "antd";
import './addMachine.css';
import './machineSchedule.css';
import axios from "axios";
import { APIURL } from "../../../Global";
import { useAlert } from 'react-alert';
import { IoIosCheckmarkCircle } from "react-icons/io";
import { DatePicker as DatePicker1 } from 'rsuite';
import moment from 'moment';
const AddMachineShedulePopup = (props) => {

    const modalContentRef = useRef(null);
    const submitButtonRef = useRef(null);
    const alert = useAlert()
    const [onload, setOnload] = useState(false)
    const [error, setError] = useState({})
    const [machines, setMachines] = useState([]);
    const [selectedDays, setSelectedDays] = useState([]);
    const [d_unit, setD_unit] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [daysOfWeek, setDaysOfWeek] = useState([0, 1, 2, 3, 4, 5, 6]);
    const [scheduleSets, setScheduleSets] = useState([{
        start_time: '',
        end_time: '',
    }]);
    const [date, setDate] = useState({
        from_date: '',
        to_date: '',
    })

    useEffect(() => {
        setSelectedDays([])
        const { from_date, to_date } = date;

        if (from_date && to_date) {
            const fromDate = moment(from_date);
            const toDate = moment(to_date);

            const timeDifference = toDate.diff(fromDate, "days");
            const enabledDays = [];

            if (timeDifference < 7) {
                const endDate = toDate.clone().add(1, "day"); // Include `to_date`

                while (fromDate.isBefore(endDate)) {
                    enabledDays.push(fromDate.day()); // Add the day of the week (0 = Sunday, etc.)
                    fromDate.add(1, "day");
                }

                setDaysOfWeek(enabledDays);
            } else {
                setDaysOfWeek([0, 1, 2, 3, 4, 5, 6]); // Default to all days if range is larger than 7 days
            }
        } else {
            setDaysOfWeek([]); // Disable all checkboxes if dates are not properly selected
        }
    }, [date.from_date, date.to_date]);

    const today = new Date().toISOString().split('T')[0];
    const generateTimeOptions = () => {
        const options = [];
        for (let i = 0; i < 24; i++) {
            const hour = i.toString().padStart(2, '0');
            options.push(`${hour}:00:00`, `${hour}:30:00`);
        }
        return options;
    };

    const generateFilteredTimeOptions = (timeOptions, scheduleSets, currentIndex, type, selectedValue) => {
        const filteredOptions = [...timeOptions];

        scheduleSets.forEach((set, index) => {
            if (index !== currentIndex && set.start_time && set.end_time) {
                const startTimeIdx = timeOptions.indexOf(set.start_time);
                const endTimeIdx = timeOptions.indexOf(set.end_time);

                if (type === 'start') {
                    if (currentIndex > index) {
                        for (let i = startTimeIdx; i <= endTimeIdx; i++) {
                            filteredOptions[i] = null;
                        }
                    } else {
                        for (let i = 0; i <= endTimeIdx; i++) {
                            filteredOptions[i] = null;
                        }
                    }
                }
            }
        });

        // Add selected value as the first option if it exists and is not null/undefined
        if (selectedValue) {
            filteredOptions.unshift(selectedValue);
        }

        return filteredOptions.filter(option => option !== null);
    };


    const generateFilteredEndTimeOptions = (timeOptions, startTime, scheduleSets, currentIndex) => {
        const startTimeIdx = timeOptions.indexOf(startTime);
        let filteredOptions = timeOptions.filter((_, index) => index > startTimeIdx);

        // Collect all selected times from previous schedule sets
        const selectedTimes = [];
        for (let i = 0; i < currentIndex; i++) {
            const set = scheduleSets[i];
            if (set.start_time && set.end_time) {
                const startTimeIdx = timeOptions.indexOf(set.start_time);
                const endTimeIdx = timeOptions.indexOf(set.end_time);

                // Push all times between start_time and end_time to selectedTimes array
                for (let j = startTimeIdx; j <= endTimeIdx; j++) {
                    selectedTimes.push(timeOptions[j]);
                }
            }
        }

        // Filter out selected times from filteredOptions
        filteredOptions = filteredOptions.filter(time => !selectedTimes.includes(time));

        return filteredOptions;

    };

    const timeOptions = generateTimeOptions();

    useEffect(() => {
        addedMachines()
    }, [])

    const addedMachines = async (currentPage) => {

        const tokenString = sessionStorage.getItem("usertoken");
        let str = tokenString.replace(/["]+/g, "");

        axios
            .get(`${APIURL}/api/v1/service-provider/dialysis-unit/?is_active=True`, {
                headers: {
                    Authorization: "Token " + str,
                },
            })
            .then((res) => {
                if (res.status === 200) {
                    const filteredData = res.data.message
                    setMachines(filteredData)
                }
            })
            .catch((err) => { });
    }

    const handleChange = (e, index) => {
        const { name, value } = e.target;
        const Errors = { ...error };

        setScheduleSets((prevSets) => {
            const updatedSets = [...prevSets];
            if (name === 'start_time') {
                const newEndTime = moment(value, 'HH:mm:ss').add(30, 'minutes').format('HH:mm:ss');

                updatedSets[index] = {
                    ...updatedSets[index],
                    start_time: value,
                    end_time: newEndTime,
                };
            }
            else {
                updatedSets[index] = {
                    ...updatedSets[index],
                    [name]: value,
                };
            }
            Errors[name] = ``
            Errors["set"] = ``
            if (index > 0 && updatedSets[index][name] === updatedSets[index - 1][name]) {
                Errors[name] = `Time cannot be same for different slotes`;
            } else {
                delete Errors[name];
            }
            setError(Errors);

            return updatedSets;
        });
    };

    const handleAddSet = () => {
        const lastSet = scheduleSets[scheduleSets.length - 1];
        if (lastSet.start_time && lastSet.end_time) {
            setScheduleSets((prevSets) => [
                ...prevSets,
                { start_time: '', end_time: '' },
            ]);
        }
    };

    const handleRemoveSet = (index) => {
        setScheduleSets((prevSets) => {
            if (prevSets.length === 1) {
                return [{ start_time: '', end_time: '' }];
            }
            const updatedSets = prevSets.filter((_, i) => i !== index);
            return updatedSets;
        });
    };

    const handleDateChange = (e) => {
        const { name, value } = e.target;
        setError(prevState => ({
            ...prevState,
            [name]: ''
        }));
        setDate(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const getMaxToDate = () => {
        if (date.from_date) {
            return moment(date.from_date).add(1, 'month').format('YYYY-MM-DD');
        }
        return '';
    };

    const handleCheckboxChange = (day) => {
        if (selectedDays.includes(day)) {
            setSelectedDays(selectedDays.filter((d) => d !== day));
        } else {
            setSelectedDays([...selectedDays, day]);
        }
    };

    const handleCheckClick = () => {
        setError(prevState => ({
            ...prevState,
            d_unit: ''
        }));

        setSelectAll(!selectAll)
        let updated_dunit = [];
        if (!selectAll) {
            updated_dunit = machines.map((machine) => machine.id)
        } else {
            updated_dunit = [];
        }
        setD_unit(updated_dunit)
        console.log(updated_dunit)
    };

    const handleMachineClick = (machineId) => {
        setError(prevState => ({
            ...prevState,
            d_unit: ''
        }));

        setD_unit((prevD_unit) => {
            if (prevD_unit.includes(machineId)) {
                return prevD_unit.filter(id => id !== machineId);
            } else {
                return [...prevD_unit, machineId];
            }
        });
        console.log(d_unit)
    };

    const validate = async () => {
        setOnload(true);
        await new Promise(resolve => setTimeout(resolve, 0));

        let input1 = scheduleSets;
        let input2 = date;
        let errors = {};
        let isValid = true;

        if (input2["from_date"] === '' || input2["from_date"] === null) {
            isValid = false;
            errors.from_date = 'Select schedule starting date';
        }
        if (input2["to_date"] === '' || input2["to_date"] === null) {
            isValid = false;
            errors.to_date = 'Select schedule ending date';
        }

        if (selectedDays.length === 0) {
            isValid = false;
            errors.days = 'Select scheduling days';
        }
        if (d_unit.length === 0) {
            isValid = false;
            errors.d_unit = 'Select machines to schedule';
        }
        if (scheduleSets[0].start_time === '' && scheduleSets[0].end_time === '') {
            isValid = false;
            errors.set = 'Select time slots';
        }

        const checkIntersection = (start1, end1, start2, end2) => {
            return start1 < end2 && start2 < end1;
        };

        for (let i = 0; i < scheduleSets.length; i++) {
            for (let j = i + 1; j < scheduleSets.length; j++) {
                const start1 = new Date(`1970-01-01T${scheduleSets[i].start_time}Z`);
                const end1 = new Date(`1970-01-01T${scheduleSets[i].end_time}Z`);
                const start2 = new Date(`1970-01-01T${scheduleSets[j].start_time}Z`);
                const end2 = new Date(`1970-01-01T${scheduleSets[j].end_time}Z`);

                if (checkIntersection(start1, end1, start2, end2)) {
                    isValid = false;
                    errors.set = 'Time slots cannot overlap';
                    break;
                }
            }
        }

        scheduleSets.forEach((set, index) => {
            if (set.start_time === set.end_time) {
                isValid = false;
                errors.set = 'Start time and end time cannot be the same';
            }
        });

        setError(errors);

        if (isValid) {
            await handleScheduleMachine();
        } else {
            alert.error('Check all the fields');
            setOnload(false);
        }
    };

    const handleScheduleMachine = async () => {
        const tokenString = sessionStorage.getItem('usertoken');
        const token = tokenString.replace(/["]+/g, '');

        const daysPayload = {};

        const dayNames = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];

        selectedDays.forEach(day => {
            daysPayload[dayNames[day]] = "True";
        });

        const slotsPayload = scheduleSets.map(set => ({
            from_time: set.start_time,
            to_time: set.end_time,
        }));

        const payload = {
            d_units: d_unit,
            days: daysPayload,
            from_date: date.from_date,
            to_date: date.to_date,
            slots: slotsPayload,
        };

        try {
            const res = await axios.post(`${APIURL}/api/v1/service-provider/dialysis-schedule/`, payload, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`,
                },
            });

            if (res.status === 200) {
                if (res.data.status === 'success') {
                    alert.success(res.data.message);
                    props.reload();
                    props.close();
                } else {
                    alert.error(res.data.message);
                }
            } else {
                alert.error(res.data.message);
            }
        } catch (err) {
        } finally {
            setOnload(false);
        }
    };


    return (
        <div className='mschedule-modal-content' ref={modalContentRef}>
            <div className='add-machine-input'>
                <div className='mschedule-head'>
                    <label>Machine Schedule</label>
                </div>
                <div className=''>
                    {(error.to_date || error.from_date) ? <label className="error-label">{error.from_date ? error.from_date : error.to_date}</label> : <label>Working dates</label>}
                    <div className='mschedule-date-inputcontainer'>
                        <div className='msinput-container col-6'>
                            <label htmlFor="date">Start date</label>
                            <input
                                className={`msinput1 ${error.from_date ? 'error-border' : ''}`}
                                type="date"
                                step="1"
                                id="from_date"
                                name="from_date"
                                min={today}
                                value={date.from_date}
                                onChange={handleDateChange}
                            />
                        </div>
                        <div className='msinput-container col-6'>
                            <label htmlFor="date">End date</label>
                            <input className={`msinput1 ${error.to_date ? 'error-border' : ''}`}
                                type="date"
                                id="to_date"
                                name="to_date"
                                disabled={date.from_date === ''}
                                min={date.from_date}
                                max={getMaxToDate()}
                                value={date.to_date}
                                onChange={handleDateChange}
                            />
                        </div>
                    </div>
                </div>
                <div className=''>
                    {(error.set || error.start_time || error.end_time) ? <label className="error-label">{error.set ? error.set : error.start_time ? error.start_time : error.end_time}</label> : <label>Working Slots</label>}
                    <div className="mschedule-inputcontainer">
                        <button
                            className="schedule-button"
                            onClick={handleAddSet}
                            disabled={
                                !scheduleSets[scheduleSets.length - 1].start_time ||
                                !scheduleSets[scheduleSets.length - 1].end_time
                            }
                        >
                            <span>+</span>
                        </button>
                        {scheduleSets.map((set, index) => (
                            <div className="schedule-set" style={{ alignItems: 'center' }} key={index}>
                                <div className='msinput-container col-5'>
                                    <label htmlFor="start_time">From</label>
                                    <select
                                        className={`msinput1 ${(error.start_time || error.set) ? 'error-border' : ''} ${(date.from_date === '' || date.to_date === '' || index !== scheduleSets.length - 1) ? 'disabled-cursor' : 'pointer'}`}
                                        id="start_time"
                                        name="start_time"
                                        disabled={date.from_date === '' || date.to_date === '' || index !== scheduleSets.length - 1}
                                        value={set.start_time}
                                        onChange={(e) => handleChange(e, index)}
                                    >
                                        {generateFilteredTimeOptions(timeOptions, scheduleSets, index, 'start', set.start_time).map((time, idx) => (
                                            <option key={idx} value={time}>
                                                {time}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className='msinput-container col-5'>
                                    <label htmlFor="end_time">To</label>
                                    <select
                                        className={`msinput1 ${(error.end_time || error.set) ? 'error-border' : ''} ${(set.start_time === '' || index !== scheduleSets.length - 1) ? 'disabled-cursor' : 'pointer'}`}
                                        id="end_time"
                                        name="end_time"
                                        disabled={set.start_time === '' || index !== scheduleSets.length - 1}
                                        value={set.end_time}
                                        onChange={(e) => handleChange(e, index)}
                                    >
                                        {generateFilteredEndTimeOptions(timeOptions, set.start_time, scheduleSets, index).map((time, idx) => (
                                            <option key={idx} value={time}>
                                                {time}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className='col-2' >
                                    <IoIosCloseCircleOutline onClick={() => handleRemoveSet(index)} style={{ margin: 0 }} className="file-remove-button" />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className=''>
                    {error.days ? <label className="error-label">{error.days}</label> : <label>Working days</label>}
                    <div className="mschedule-daycontainer">
                        {[0, 1, 2, 3, 4, 5, 6].map((day) => (
                            <div key={day} className="day-checkbox">
                                <input
                                    type="checkbox"
                                    id={`day-${day}`}
                                    name={`day-${day}`}
                                    value={day}
                                    checked={selectedDays.includes(day)}
                                    onChange={() => handleCheckboxChange(day)}
                                    disabled={!daysOfWeek.includes(day)} // Disable if the day is not in the enabled list
                                />
                                <label htmlFor={`day-${day}`}>
                                    {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"][day]}
                                </label>
                            </div>
                        ))}
                    </div>
                </div>
                <div className=''>
                    <div>
                        {error.d_unit ? <label className="error-label">{error.d_unit}</label> : <label>Select Machine</label>}
                        <label style={{ marginLeft: "75%" }}>Select All
                            &nbsp;&nbsp;<input
                                style={{ marginTop: "1%" }}
                                type="checkbox"
                                checked={selectAll}
                                onChange={handleCheckClick}
                                id="select-all"
                            />
                        </label>
                    </div>
                    <div className='mschedule-machinecontainer'>
                        {machines.length > 0 && machines.map((machine, index) => (
                            <div key={index}
                                className='mschedule-card'
                                onClick={() => { handleMachineClick(machine.id) }}
                                style={{
                                    border: (d_unit.length > 0 && d_unit.includes(machine.id)) ? '1px solid blue' : '',
                                    backgroundColor: (d_unit.length > 0 && d_unit.includes(machine.id)) ? '#c6e2fb' : ''
                                }}
                            >
                                <div style={{ flex: 1 }}>
                                    <div className='dialysis-icon3'>
                                        <svg width="25" height="23" viewBox="0 0 69 66" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect width="100%" height="100%" fill="url(#pattern0_2828_2823)" />
                                            <defs>
                                                <pattern id="pattern0_2828_2823" patternContentUnits="objectBoundingBox" width="1" height="1">
                                                    <use href="#image0_2828_2823" transform="matrix(0.00956522 0 0 0.01 0.0217391 0)" />
                                                </pattern>
                                                <image id="image0_2828_2823" width="100" height="100" href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAYAAABw4pVUAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEP0lEQVR4nO2dX4hVRRzHZxVJYdNK7cFA2S0fwofUFUmKzEDw2ZDSHiqEUjKkFcU/D4a7wYKCz+tDYW/e1SLqYckUMagkqXwRFUp78EVJTbMlV/Yjg/NwV7Z77t4zc8/Mb34fGFiW3Z3fdz7cc87OmTPHGEVRFEVRFEVRFKEAS4E9wCGglkg7BOy2tRspAC8BZ0ifn4AVJmWAzcAochgFNpkUAV4HxpDHGPCmSQlgDnALudwCZptUAD5BPv0mFYCLyOeSSQFgJvnwuIkdYGETQYYj+B+jVtBsjUUsNLEDLGoiyCITOQjJISYIQnKICYKQHC0FAbqBnopbd9kckoTYk2jV1MrmiBIVEhkqJDJUSGRIOfYiJIeYIAjJISYIQnKICULmJ/UB4GzFbaBsjijRq6zIUCGRoUJkCFkBrKu4jVtzlfU5JEYQkkNMEITkEBMEITmaDdJjIgdYlpOQ08B8EynAAuD7nIRY/gE+BVYB0yKoexrwGvCZq43chNRzF/gO6LMLmYHFwPSANc5wfdi++l3ftobJUp0QoMOFWFtwPd+LP64BvwJfu0/TQWAvsBV4z7UNdX1vqPv+VvezB93v2r/xm/ubvugtGIu1bsw6fMt4FvjZY5DcOGPH0JeMTuBy1YkE8IcdSx9CPqw6iSC2+BDyOfFz37XYOexDSAyL2IrYAewkfmo5CDnqrgBtO0bciBfyJ/BUXa1PuJNnrIgWMjrRc+PAcuA/MhbSyiJlH2xvUHO7zieTXTQuVshxYEqDmqe46ZDQqBAeTvyNe5ajwYztbRUS/hOyaRKf7g9USFghvzQ6VP3PoSvkHFzWh6wx4OUW6n+FcGQtZLhEBnsREIKshawukWFNoJqyFfJXmZs8blrlaoC6shVy3EOObwLUla2QUx5y2JUuvslWyA3gsRIZpgM3A9SVrRDLOyUyvEsYshZyDZjXQv3PANcD1ZS1EMsFoGsStXcF3t0ueyG4CcOPGq3gsDu9AduAO4RFhTyyM+gX7t7H267Zr78E/qY9qJDIUCGRoUIiI0ohQxPsDnc2k9b9SPahdggZLOjkZOlOhACcKhirQR+dfFzQyb92r3eTOcBcYKRgrPb66OgtijkCTDWZAkx1KyiLWO+js9lNvgPkBLDSPp1kMoGHT2K92sShynIPeNJXx+1Y4ySdb73IaMNCgVxY5U2Ik2Kf0VNa4yuvMuremvN7iwXlzBXgae9CnJTFge68SeUG8EIQGXVSngPOV500AS4BzweVUSdlFnCgiX+EcmQE2G/fNNQWGROsMN8HnKt6FCLgnBuL+TFNHdjXq24kHzYCS2x2Eyvu9mkudJoUcIsRpHPepILbYUc6fSYV7CSaWyAtlZtJvXrV4rYmkvpy4jdMitjnAYW9vvse8L5JGbdJ8o+kzw/Ai0YK7np9l7tHX0ukDbqal1Q9foqiKIqiKIqiKCYQDwAkUKz1Q+aAAwAAAABJRU5ErkJggg==" />
                                            </defs>
                                        </svg>
                                    </div>
                                </div>
                                <label className='model-name'>{machine.unit_no}</label>
                                <label className='unit-no'>{machine.model_name}</label>
                                <label className='machine-status' style={{ color: (machine.is_active === true && machine.is_standby === false) ? "#3DB642" : "#666" }}>
                                    {machine.is_standby ? "Back Up" : "Active"}
                                </label>
                                <div style={{ flex: 1, color: "#2B69A3", textAlign: 'center' }}>{(d_unit.length > 0 && d_unit.includes(machine.id)) && <IoIosCheckmarkCircle />}</div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className='mschedule-button-container'>
                {!props.primary && (
                    <button ref={submitButtonRef} onClick={() => { props.close() }} className='done-button'>Cancel</button>
                )}
                <button ref={submitButtonRef} disabled={onload} onClick={() => { validate() }} className='done-button' style={{ cursor: onload ? 'progress' : '' }}>Done</button>
            </div>
        </div >
    )
};

export default AddMachineShedulePopup;