import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from "axios";
import { APIURL } from "../../Global";
import { useAlert } from 'react-alert';

const AdmitShow = ({ onCancel, onConfirm, patient_id, appt_id, appt_type }) => {
    const [departments, setDepartments] = useState([])
    const [days, setDays] = useState('')
    const [type, setType] = useState({})
    const alert = useAlert()

    useEffect(() => {
        const tokenString = sessionStorage.getItem("usertoken");

        let str = tokenString.replace(/["]+/g, "");
        axios

            .get(`${APIURL}/api/v1/service-provider/master-departments/`, {
                headers: {
                    Authorization: "Token " + str,
                },
            })
            .then((res) => {
                if (res.status === 200) {
                    let data = []
                    res.data
                        .filter(item => ![1, 2, 6].includes(item.id))
                        .map((item) => {
                            data = ([...data, { value: item.id, label: item.department_name }]);
                        });
                    setDepartments(data);
                } else {
                    alert.error(res.data.message);
                }
            });
    }, []);

    const handleChange = (e) => {
        e.preventDefault();
        setDays(e.target.value)
    }

    const departmentData = (data) => {
        const selectedValue = +data;
        const selectedDepartment = departments.find(department => department.value === selectedValue);
        setType({
            value: data,
            label: selectedDepartment ? selectedDepartment.label : '',
        },
        );
    };

    const confirm = () => {
        if (type.label === undefined) {
            alert.error("Select a department")
        }
        else if (!days || days === undefined || days == '' || days == null){
            alert.error("Add Period of Stay")
        }
        else {
            const tokenString = sessionStorage.getItem("usertoken");
            let str = tokenString.replace(/["]+/g, "");
            const payload = {
                department: +type.value,
                appt_id: appt_id,
                appt_type: appt_type,
                ...(days !== undefined && days !== '' ? { period_of_stay: days } : {})
            };

            axios
                .post(`${APIURL}/api/v1/doctor/admit-patient/?patient_id=${patient_id}&type=${type.label}`, payload, {
                    headers: {
                        Authorization: "Token " + str,
                        "Content-Type": "application/json",
                    },
                })

                .then((res) => {
                    ;
                    if (res.data.status === "success") {
                        alert.success("Admission marked successfully")
                        onConfirm()
                    } else {
                        alert.error(res.data.message);
                    }
                })
                .catch((err) => {
                    alert.error("error in submission")
                });
        }
    }

    return (
        <div>
            <Modal
                show={true} centered
            >
                <Modal.Body style={{ textAlign: "left" }}>
                    <div style={{ display: "flex" }} >
                        <label style={{ flex: 11, textAlign: "center", fontSize: "24px", fontWeight: 400 }} className='blue-label'>Confirm Admit</label>
                    </div>
                    <div className="col-12">
                        <select
                            style={{
                                width: "100%",
                                height: "50px",
                                flexShrink: "0",
                                borderRadius: "10px",
                                border: "1px solid #9A9A9A",
                                color: "#000",
                                background: "#FFF",
                            }}
                            value={type.value}
                            onChange={(e) => departmentData(e.target.value)}
                            name="roomtype"
                        >
                            <option value="">Select department</option>{" "}
                            {/* Add a default option */}
                            {departments.map((department) => (
                                <option key={department.value} value={department.value}>
                                    {department.label}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className='col-12' style={{ marginTop: "15px" }}>
                        <div className='input-container'>
                            <label>Period of stay</label>
                            <input
                                type="number"
                                className="input1"
                                placeholder="No. of days"
                                maxLength="40"
                                min="0"
                                value={days}
                                name="days"
                                onKeyDown={(e) => {
                                    if (e.key === '-' || e.key === 'e') {
                                        e.preventDefault();
                                    }
                                }}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div style={{ display: 'flex' }}>
                        <div className='col-6'></div>
                        <button className='col-3' style={{ display: "block", height: "40px", margin: '10px 3px 0px 0px', borderRadius: "3px", color: "#fff", backgroundColor: '#dc3545', width: "100px" }} onClick={() => onCancel()}> Cancel</button>
                        <button className='col-3' style={{ display: "block", height: "40px", margin: '10px 0px 0px 3px', borderRadius: "3px", color: "#fff", backgroundColor: '#2b69a3', width: "100px" }} onClick={() => confirm()}> Confirm</button>
                    </div>

                </Modal.Body>
            </Modal>
        </div>
    );
};

export default AdmitShow;
